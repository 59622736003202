import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RouteLayout from './routes/RouteLayout';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouteLayout />
    </QueryClientProvider>
  );
};

export default App;
