import * as React from 'react';
import { DataGrid, GridColDef, GridFeatureMode } from '@mui/x-data-grid';

interface Business {
  id?: String;
  name?: String;
  address?: String;
  description?: String;
  userId?: String;
  postalCode?: String;
  phone?: String;
  countryId?: String;
  email?: String;
  createdAt?: String;
  updatedAt?: String;
  logo?: String;
}

interface DataTableProps {
  rows: Business[];
  columns: GridColDef[];
  loading: boolean;
  page: number;
  pageSize: number
  count: number;
  setPage: (page: number) => any;
  setPageSize: (pageSize: number) => any;
}

export default function DataTable({ rows, page, pageSize, columns, count, loading, setPage, setPageSize }: DataTableProps) {
  return (
    <div style={{ height: '85.5vh', width: '100%' }}>
       <DataGrid
        rows={rows}
        rowCount={count}
        loading={loading}
        //@ts-ignore
        rowsPerPageOptions={[5,10,20,50,100]}
        pagination
        page={page}
        pageSize={pageSize}
        paginationMode='server'
        onPageChange={(newPage: number) => setPage(newPage)}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        columns={columns}
      />
    </div>
  );
}

DataTable.defaultProps = {
  columns: [],
  rows: [],
  loading: false,
  getPageNumber: () => null,
  changePage: () => null,
}
