import React from 'react';
import useAuthUser from '../../../hooks/useAuthUser';
import Button from '@mui/material/Button';

const Logout: React.FC = () => {
  const { logout } = useAuthUser();

  return (
    <Button color="inherit" onClick={() => logout()}>
      Logout
    </Button>
  );
};

export default Logout;
