import { authInstance } from '../services/axios';
import { useMutation } from '@tanstack/react-query';
import useAuthUser from './useAuthUser';
import { useNavigate } from 'react-router-dom';

const signIn = async (userData: any): Promise<any> => {
  const response = await authInstance.post('user-service/auth/login', userData);
  return response.data;
};

export const useSignIn = () => {
  const { setAuthUser } = useAuthUser();
  const navigation = useNavigate();

  return useMutation(['signin'], signIn, {
    onSuccess: response => {
      const token = response.response.token;

      setAuthUser(token);
      navigation('/dashboard');
    },
  });
};
