import React from 'react';
import Header from '../../components/ui/Header';
import AdminDashboard from '../../components/ui/AdminDashboard';

const Dashboard: React.FC = () => {
  return (
    <React.Fragment>
      {/* <Header /> */}
      <AdminDashboard />
    </React.Fragment>
  );
};

export default Dashboard;
