import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { fieldNames } from './enumerations';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { useSignIn } from '../../hooks/useSignIn';
import ErrorMessage from '../../components/common/ErrorMessage';
import { toast } from 'react-toastify';
const theme = createTheme();

export const SignIn: React.FC = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
  });

  const { mutate, error }: any = useSignIn();

  React.useEffect(() => {
    if (error) {
      toast.error(error.response.data.errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [error]);

  const onFormSubmit = async (values: any) => {
    mutate(values);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Controller
              name={fieldNames.email}
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  required
                  fullWidth
                  value={value}
                  onChange={onChange}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              )}
            />
            <ErrorMessage errors={errors} name={fieldNames.email} />
            <Controller
              name={fieldNames.password}
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={value}
                  onChange={onChange}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              )}
            />
            <ErrorMessage errors={errors} name={fieldNames.password} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
