import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logout from '../LogoutButton';
import { Link } from 'react-router-dom';
import '../../../App.css'

export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link to='/dashboard' className='header_link' >Admin Panel</Link>
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {/* Admin Panel */}
              <Link to='/teaser' className='header_link' >Teaser</Link>
            </Typography>
          </div>
          <Logout />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
