import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect } from 'react'
import DataTable from '../../components/common/Dashboard'
import { useBusiness } from '../../hooks/useBusiness';
import delete_icon from '../../assets/img/delete_icon.png'
import edit_icon from '../../assets/img/edit_icon.png'
import { apiInstance } from '../../services/axios';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const Teaser = () => {
    const navigate = useNavigate()
    const [search, setSearch] = React.useState('');
    const [pageSize, setPageSize] = React.useState(20);
    const [page, setPage] = React.useState(0);
    const [teaserData, setTeaserData] = React.useState([]);

    const TeaserGetData = () => {
        apiInstance.get('teaser-service/offer?status=all').then((res) => {
            setTeaserData(res.data.response)
        })
    }

    useEffect(() => {
        TeaserGetData()
    }, [])

    const onDelete = (id: any) => {
        let deleteData = apiInstance.delete(`/teaser-service/offer/${id}`).then((res) => {
            TeaserGetData()
        })
    }


    const columns: GridColDef[] = [
        {
            field: 'logo',
            renderCell(params) {
                return <img style={{}} src={params.row.img_url} alt='Logo' height='80%' />
            },
            headerName: 'Logo',
            flex: 1,
        },
        { field: 'title', headerName: 'Title', flex: 1 },
        { field: 'description', headerName: 'description', flex: 1, },
        { field: 'offer_type', headerName: 'Offer type', flex: 1, },
        { field: 'status', headerName: 'status', flex: 1, },
        { field: 'sort_order', headerName: 'Sort order', flex: 1, },
        {
            field: 'Login',
            renderCell(params) {
                return <div style={{ display: 'flex', gap: '10px' }}>
                    <button style={{ backgroundColor: 'transparent', border: 'none', padding: '10px 16px', color: '#FFFFFF', borderRadius: '3px', cursor: 'pointer' }}
                        onClick={() => onDelete(params.id)}>
                        <img src={delete_icon} alt='' /></button>
                    <button style={{ backgroundColor: 'transparent', border: 'none', padding: '10px 16px', color: '#FFFFFF', borderRadius: '3px', cursor: 'pointer' }}
                        onClick={() => navigate(`/teaser/edit/${params.id}`)}>
                        <img src={edit_icon} alt='' /></button>
                </div>
            },
            headerName: 'Action',
            flex: 1,
        },
    ]

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '24px' }}>
                <h2 style={{ margin: '0px' }}>Teaser</h2>
                <Button variant="contained" sx={{}}
                    onClick={() => navigate('/teaser/add')}
                >
                    Add
                </Button>
            </div>
            <div style={{ height: '85.5vh', width: '100%' }}>
            <DataGrid
                rows={teaserData}
                rowCount={teaserData.length}
                loading={false}  
                //@ts-ignore
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                pagination
                page={page}
                pageSize={pageSize}
                paginationMode="client"
                onPageChange={(newPage: number) => setPage(newPage)}
                onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                columns={columns}
            />
            </div>
        </React.Fragment>
    )
}

export default Teaser