import { QueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const useAuthUser = () => {
  const queryClient = new QueryClient();
  const history = useNavigate();
  const navigate = useNavigate()

  const setAuthUser = (token: string) => {
    localStorage.setItem('access_token', token);
    navigate('/');
  };

  const logout = () => {
    queryClient.clear();
    localStorage.clear();
    navigate('/');
  };

  return { logout, setAuthUser };
};

export default useAuthUser;
