import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import PrivateRoute from '../components/common/PrivateRoute';
import Layout from '../layout';
import AuthLayot from '../layout/AuthLayot';
import Dashboard from './Dashboard';
import SignUp from './SignUp';
import Login from './SignIn';
import Teaser from './Teaser';
import AddTeaser from './Teaser/AddTeaser';
import AdminDashboard from '../components/ui/AdminDashboard';
import TeaserOutlet from './Teaser/TeaserOutlet';

const Index = () => {

    const loction = useLocation()
    const navigate = useNavigate()
    // const isLogin = !!localStorage.getItem('access_token');
    // const [isAuth, setAuth] = useState(isLogin);

    // useEffect(() => {
    //     setAuth(isLogin)
    // }, [isLogin])

    const forLayout = ["/signup", "/"];
    const forAuthLayout = [
        "/dashboard",
        "/teaser",
        "/teaser/add",
    ];

    useEffect(() => {
        if ((localStorage.getItem('access_token') !== null || sessionStorage.getItem('access_token') !== null)) {
            if (!forAuthLayout.includes(loction.pathname)) {
                navigate("/dashboard");
            }
        }
    }, []);

    return (
        <>
            {
                forLayout.includes(loction.pathname) ?
                    <Layout>
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/registration" element={<SignUp />} />
                        </Routes >
                    </Layout>
                    :
                    <AuthLayot>
                        <Routes>
                            <Route path="/" element={<AdminDashboard />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/teaser" element={<TeaserOutlet />} >
                                <Route path="" element={<Teaser />} />
                                <Route path="add" element={<AddTeaser />} />
                                <Route path="edit/:id" element={<AddTeaser />} />
                            </Route>
                        </Routes >
                    </AuthLayot>
            }
        </>
    )
}

export default Index