import axios from 'axios';

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL
});

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

apiInstance.interceptors.request.use(
  (config: any) => {
    config.headers['Authorization'] = `${localStorage.getItem('access_token')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export { authInstance, apiInstance };
