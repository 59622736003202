import React, { useEffect, useState } from 'react'
import Header from '../components/ui/Header';

const Layout = ({ children }: any) => {

    return (
        <div className='layout'>
            <div>{children}</div>
        </div>
    )
}

export default Layout