import { BrowserRouter } from 'react-router-dom';
import Pages from '../pages';
const RouteLayout = () => {
    return (
        <BrowserRouter>
            <Pages />
        </BrowserRouter>
    )
}

export default RouteLayout