import React from 'react'
import PrivateRoute from '../components/common/PrivateRoute';
import Header from '../components/ui/Header';

const AuthLayot = ({ children }: any) => {
    return (
        <div>
            <Header />
            <PrivateRoute>
                <div>{children}</div>
            </PrivateRoute>
        </div>
    )
}

export default AuthLayot