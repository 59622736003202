import { useQuery } from '@tanstack/react-query';
import { apiInstance } from '../services/axios';

interface Query {
  [key: string]: string;
}

const getBusiness = async (query: Query | undefined, page: number, limit: number, search: string): Promise<any> => {
  let URL = `/business-service/business?page=${page}&limit=${limit}${search &&`&search=${search}`}`;
  if (query) {
    URL = URL.concat(`&name=${query}`)
  }
  const { data } = await apiInstance.get(URL);
  return data;
};

export const useBusiness = (query: Query | undefined, page: number, limit: number, search:string) => {
  return useQuery(['business'], () => getBusiness(query, page, limit, search));
};
