import { GridColDef } from '@mui/x-data-grid';
import { apiInstance, authInstance } from '../../../../services/axios';

export const columns: GridColDef[] = [
  {
    field: 'logo',
    renderCell(params) {
      return <img src={params.row.logo} alt='Logo' height='100%' />
    },
    headerName: 'Logo',
    flex: 1,
  },
  { field: 'name', headerName: 'Company Name', flex: 1 },
  {
    field: 'email',
    headerName: 'Registered Email',
    flex: 1,
    valueGetter: (params) => params.row.params?.extra_fields?.email || params.value || '',
  },
  {
    field: 'shopify_domain',
    headerName: 'Connected shop name',
    flex: 1,
    valueGetter: (params) => params.row.shopify_params?.myshopifyDomain || '',
  }, 
  {
    field: 'shopify_status',
    headerName: 'Status',
    flex: 1,
    valueGetter: (params) => params.row.shopify_params?.status || '',
  }, 
  {
    field: 'created_at',
    headerName: 'Install date',
    flex: 1,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      return date.toLocaleDateString(); // Adjust the format as per your requirements
    },
  },
  {
    field: 'Country',
    headerName: 'Connected shop name',
    flex: 1,
    valueGetter: (params) => params.row.params?.extra_fields?.country || params.value || '',
  }, 
  { field: 'campaign_launched', headerName: 'Launched campaigns', flex: 1 },
  { field: 'reward_won', headerName: 'Rewards Won', flex: 1 },
  { field: 'customers_count', headerName: 'Total customers', flex: 1 },
  { field: 'new_customers_count', headerName: 'Total new customers', flex: 1 },
  { field: 'royalty_participants', headerName: 'Loyalty program(app) participants', flex: 1 },
  {
    field: 'Login',
    renderCell(params) {
      return <button style={{ backgroundColor: '#1976d2', border: 'none', padding: '10px 16px', color: '#FFFFFF', borderRadius: '3px' }} onClick={async () => {
        const body = {
          b_id: params.row.id
        }
        const response = await apiInstance.post('user-service/admin/generate-token', body).then((res) => {
          const t = res.data.response.token.replace('Bearer ', '')
          const win = window.open(`${process.env.REACT_APP_REDIRECTURL}?token=${t}`)!;
          win.open();
        });
      }}>
        Login</button>
    },
    headerName: 'Action',
    flex: 1,
  },
  { field: 'id', headerName: 'ID', flex: 1 },
];
